.timeSheetpopover {
  padding: 5px;
  border-radius: 20px;
  box-shadow: none;
}
.backButton {
  border-radius: 100%;
  padding: 8px;
  cursor: pointer;
  display: inline-flex;
  background-color: #f0f0f0;
  align-items: center;
}
.TimesheetHeading {
  border-bottom: 2px solid #919eab66;
}
.TimesheetHeading label {
  color: #212b36;
}
.timesheetcircle::before {
  content: '';
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 10px;
  border-radius: 50%;
  background-color: #103558;
}
h6.timesheetcircle {
  position: relative;
}
.actualLogTitle {
  border-bottom: 1px solid #000000;
}
.timesheetDetails {
  border-left: 1px solid #787878;
  padding-left: 30px;
}
