.progress {
  height: 2px;
  width: 100%;
  position: absolute;
  top: 25px;
  left: 0;
  padding: 0 40px;
}
#skk-tabs {
  z-index: 9;
  position: relative;
}
.rmsc .dropdown-container:focus-within {
  box-shadow: none !important;
  border-color: transparent !important;
}
.dropdown.show > .form-select.form-select-solid,
.form-select.form-select-solid:active,
.form-select.form-select-solid.active,
.form-select.form-select-solid:focus,
.form-select.form-select-solid.focus {
  border: 1px solid rgb(145, 158, 171) !important;
  background-color: transparent !important;
}
.dropdown.show > .form-control.form-control-solid,
.form-control.form-control-solid:active,
.form-control.form-control-solid.active,
.form-control.form-control-solid:focus,
.form-control.form-control-solid.focus {
  border: 1px solid rgb(145, 158, 171) !important;
  background-color: transparent !important;
}
.inputTitle {
  font-size: 16px;
}
.inputFieldStyle {
  border: 1px solid #919eab;
  color: #000000 !important;
  padding: 15px;
}
.addDocumentButton {
  min-width: 200px;
}
.selectedDocumentBox {
  box-shadow: none;
  border-radius: 8px;
  padding: 12px 15px;
  background-color: #faf6f6;
  border: 1px solid #ffac9a;
}
.selectedDateView {
  gap: 10px;
}
.remove-icon {
  margin-left: 5px;
  cursor: pointer;
  fill: #103558 !important;
}
.inputStyleField {
  border: 1px solid rgb(145, 158, 171);
  color: black;
  padding: 15px;
  margin-right: 10px;
}
.rateTitle {
  width: 50%;
  display: flex;
  align-items: center;
  margin-top: 30px;
}
.WeeklyRate {
  border: 1px solid #919eab;
  color: #000000;
  padding: 15px;
  background-color: #e0e0e0;
}
.inviteCheckbox {
  width: 20px;
  height: 15px;
}
.viewInviteBtn {
  height: 30px;
  border: 1px solid #103558;
  border-radius: 8px;
  padding: 10px;
}
.viewInviteBtn span {
  font-weight: bold;
  color: #103558;
  padding-left: 10px;
  padding-right: 10px;
}
.findCheckBox {
  height: 20px;
  width: 20px;
  border: 1px solid #103558;
}
.clinicianImage {
  height: 40px;
  width: 40px;
  border-radius: 50px;
  object-fit: contain;
  object-position: center;
}
.clinicianUser {
  font-family: 'Red Hat Display';
  line-height: 70px;
}
.clinicianUserText {
  font-size: 14px;
}
.clinicianSkillstatus {
  width: 80px;
  height: 24px;
  position: absolute;
  padding: 4px 10px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.findClinician {
  border-bottom: 2px solid #919eab66;
  padding: 10px 22px;
}

.ctmTime input{
  height: 5px;
}
