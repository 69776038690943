.backArrow {
  border-radius: 100%;
  padding: 8px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  background-color: #f0f0f0;
}
.steperBtn {
  width: 50px;
  height: 50px;
}
.actionPopup {
  padding: 10px;
  border-radius: 20px;
}
.addAssignment {
  border-radius: 8px;
}
.inviteImage {
  height: 40px;
  width: 40px;
  border-radius: 50px;
  object-fit: cover;
}
.invitedUser {
  font-family: 'Red Hat Display';
}
.invitedUser{
    font-family: "Red Hat Display"; 
}
.inviteAddress{
    text-align: center;
    line-height: 20px;
    padding: 5px;
    margin: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: 'Red Hat Display';
}
.inviteDetailTitle{
    border-bottom: 1px solid #919EAB;
}
.titleFont{
    font-size: 18px !important;
}
.viewInviteDetails{ 
    font-weight: 400;
    /* line-height: 11px; */
    color: #212B36;
}
.noteDescription{
  font-size: 14px;
  font-weight: 400;
}
.inviteDetailCard{
  border-bottom: 1px solid #919eab;
  padding-bottom: 5px;
}
.viewInvitePopover{
  padding: 10px 10px 4px 10px;
  border-radius: 20px;
}