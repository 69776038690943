@font-face {
  font-family: 'keenicons-outline';
  src: url('fonts/keenicons-outline.eot?fzo4bm');
  src: url('fonts/keenicons-outline.eot?fzo4bm#iefix')
      format('embedded-opentype'),
    url('fonts/keenicons-outline.ttf?fzo4bm') format('truetype'),
    url('fonts/keenicons-outline.woff?fzo4bm') format('woff'),
    url('fonts/keenicons-outline.svg?fzo4bm#keenicons-outline') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.ki-outline {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'keenicons-outline' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ki-abstract-1.ki-outline:before {
  content: '\e900';
}
.ki-abstract-2.ki-outline:before {
  content: '\e901';
}
.ki-abstract-3.ki-outline:before {
  content: '\e902';
}
.ki-abstract-4.ki-outline:before {
  content: '\e903';
}
.ki-abstract-5.ki-outline:before {
  content: '\e904';
}
.ki-abstract-6.ki-outline:before {
  content: '\e905';
}
.ki-abstract-7.ki-outline:before {
  content: '\e906';
}
.ki-abstract-8.ki-outline:before {
  content: '\e907';
}
.ki-abstract-9.ki-outline:before {
  content: '\e908';
}
.ki-abstract-10.ki-outline:before {
  content: '\e909';
}
.ki-abstract-11.ki-outline:before {
  content: '\e90a';
}
.ki-abstract-12.ki-outline:before {
  content: '\e90b';
}
.ki-abstract-13.ki-outline:before {
  content: '\e90c';
}
.ki-abstract-14.ki-outline:before {
  content: '\e90d';
}
.ki-abstract-15.ki-outline:before {
  content: '\e90e';
}
.ki-abstract-16.ki-outline:before {
  content: '\e90f';
}
.ki-abstract-17.ki-outline:before {
  content: '\e910';
}
.ki-abstract-18.ki-outline:before {
  content: '\e911';
}
.ki-abstract-19.ki-outline:before {
  content: '\e912';
}
.ki-abstract-20.ki-outline:before {
  content: '\e913';
}
.ki-abstract-21.ki-outline:before {
  content: '\e914';
}
.ki-abstract-22.ki-outline:before {
  content: '\e915';
}
.ki-abstract-23.ki-outline:before {
  content: '\e916';
}
.ki-abstract-24.ki-outline:before {
  content: '\e917';
}
.ki-abstract-25.ki-outline:before {
  content: '\e918';
}
.ki-abstract-26.ki-outline:before {
  content: '\e919';
}
.ki-abstract-27.ki-outline:before {
  content: '\e91a';
}
.ki-abstract-28.ki-outline:before {
  content: '\e91b';
}
.ki-abstract-29.ki-outline:before {
  content: '\e91c';
}
.ki-abstract-30.ki-outline:before {
  content: '\e91d';
}
.ki-abstract-31.ki-outline:before {
  content: '\e91e';
}
.ki-abstract-32.ki-outline:before {
  content: '\e91f';
}
.ki-abstract-33.ki-outline:before {
  content: '\e920';
}
.ki-abstract-34.ki-outline:before {
  content: '\e921';
}
.ki-abstract-35.ki-outline:before {
  content: '\e922';
}
.ki-abstract-36.ki-outline:before {
  content: '\e923';
}
.ki-abstract-37.ki-outline:before {
  content: '\e924';
}
.ki-abstract-38.ki-outline:before {
  content: '\e925';
}
.ki-abstract-39.ki-outline:before {
  content: '\e926';
}
.ki-abstract-40.ki-outline:before {
  content: '\e927';
}
.ki-abstract-41.ki-outline:before {
  content: '\e928';
}
.ki-abstract-42.ki-outline:before {
  content: '\e929';
}
.ki-abstract-43.ki-outline:before {
  content: '\e92a';
}
.ki-abstract-44.ki-outline:before {
  content: '\e92b';
}
.ki-abstract-45.ki-outline:before {
  content: '\e92c';
}
.ki-abstract-46.ki-outline:before {
  content: '\e92d';
}
.ki-abstract-47.ki-outline:before {
  content: '\e92e';
}
.ki-abstract-48.ki-outline:before {
  content: '\e92f';
}
.ki-abstract-49.ki-outline:before {
  content: '\e930';
}
.ki-abstract.ki-outline:before {
  content: '\e931';
}
.ki-add-files.ki-outline:before {
  content: '\e932';
}
.ki-add-folder.ki-outline:before {
  content: '\e933';
}
.ki-add-item.ki-outline:before {
  content: '\e934';
}
.ki-add-notepad.ki-outline:before {
  content: '\e935';
}
.ki-address-book.ki-outline:before {
  content: '\e936';
}
.ki-airplane-square.ki-outline:before {
  content: '\e937';
}
.ki-airplane.ki-outline:before {
  content: '\e938';
}
.ki-airpod.ki-outline:before {
  content: '\e939';
}
.ki-android.ki-outline:before {
  content: '\e93a';
}
.ki-angular.ki-outline:before {
  content: '\e93b';
}
.ki-apple.ki-outline:before {
  content: '\e93c';
}
.ki-archive-tick.ki-outline:before {
  content: '\e93d';
}
.ki-archive.ki-outline:before {
  content: '\e93e';
}
.ki-arrow-circle-left.ki-outline:before {
  content: '\e93f';
}
.ki-arrow-circle-right.ki-outline:before {
  content: '\e940';
}
.ki-arrow-diagonal.ki-outline:before {
  content: '\e941';
}
.ki-arrow-down-left.ki-outline:before {
  content: '\e942';
}
.ki-arrow-down-refraction.ki-outline:before {
  content: '\e943';
}
.ki-arrow-down-right.ki-outline:before {
  content: '\e944';
}
.ki-arrow-down.ki-outline:before {
  content: '\e945';
}
.ki-arrow-left.ki-outline:before {
  content: '\e946';
}
.ki-arrow-mix.ki-outline:before {
  content: '\e947';
}
.ki-arrow-right-left.ki-outline:before {
  content: '\e948';
}
.ki-arrow-right.ki-outline:before {
  content: '\e949';
}
.ki-arrow-two-diagonals.ki-outline:before {
  content: '\e94a';
}
.ki-arrow-up-down.ki-outline:before {
  content: '\e94b';
}
.ki-arrow-up-left.ki-outline:before {
  content: '\e94c';
}
.ki-arrow-up-refraction.ki-outline:before {
  content: '\e94d';
}
.ki-arrow-up-right.ki-outline:before {
  content: '\e94e';
}
.ki-arrow-up.ki-outline:before {
  content: '\e94f';
}
.ki-arrow-zigzag.ki-outline:before {
  content: '\e950';
}
.ki-arrows-circle.ki-outline:before {
  content: '\e951';
}
.ki-arrows-loop.ki-outline:before {
  content: '\e952';
}
.ki-artificial-intelligence.ki-outline:before {
  content: '\e953';
}
.ki-auto-brightness.ki-outline:before {
  content: '\e954';
}
.ki-avalanche.ki-outline:before {
  content: '\e955';
}
.ki-award.ki-outline:before {
  content: '\e956';
}
.ki-badge.ki-outline:before {
  content: '\e957';
}
.ki-bandage.ki-outline:before {
  content: '\e958';
}
.ki-bank.ki-outline:before {
  content: '\e959';
}
.ki-barcode.ki-outline:before {
  content: '\e95a';
}
.ki-basket-ok.ki-outline:before {
  content: '\e95b';
}
.ki-basket.ki-outline:before {
  content: '\e95c';
}
.ki-behance.ki-outline:before {
  content: '\e95d';
}
.ki-bill.ki-outline:before {
  content: '\e95e';
}
.ki-binance-usd.ki-outline:before {
  content: '\e95f';
}
.ki-binance.ki-outline:before {
  content: '\e960';
}
.ki-bitcoin.ki-outline:before {
  content: '\e961';
}
.ki-black-down.ki-outline:before {
  content: '\e962';
}
.ki-black-left-line.ki-outline:before {
  content: '\e963';
}
.ki-black-left.ki-outline:before {
  content: '\e964';
}
.ki-black-right-line.ki-outline:before {
  content: '\e965';
}
.ki-black-right.ki-outline:before {
  content: '\e966';
}
.ki-black-up.ki-outline:before {
  content: '\e967';
}
.ki-bluetooth.ki-outline:before {
  content: '\e968';
}
.ki-book-open.ki-outline:before {
  content: '\e969';
}
.ki-book-square.ki-outline:before {
  content: '\e96a';
}
.ki-book.ki-outline:before {
  content: '\e96b';
}
.ki-bookmark-2.ki-outline:before {
  content: '\e96c';
}
.ki-bookmark.ki-outline:before {
  content: '\e96d';
}
.ki-bootstrap.ki-outline:before {
  content: '\e96e';
}
.ki-briefcase.ki-outline:before {
  content: '\e96f';
}
.ki-brifecase-cros.ki-outline:before {
  content: '\e970';
}
.ki-brifecase-tick.ki-outline:before {
  content: '\e971';
}
.ki-brifecase-timer.ki-outline:before {
  content: '\e972';
}
.ki-brush.ki-outline:before {
  content: '\e973';
}
.ki-bucket-square.ki-outline:before {
  content: '\e974';
}
.ki-bucket.ki-outline:before {
  content: '\e975';
}
.ki-burger-menu-1.ki-outline:before {
  content: '\e976';
}
.ki-burger-menu-2.ki-outline:before {
  content: '\e977';
}
.ki-burger-menu-3.ki-outline:before {
  content: '\e978';
}
.ki-burger-menu-4.ki-outline:before {
  content: '\e979';
}
.ki-burger-menu-5.ki-outline:before {
  content: '\e97a';
}
.ki-burger-menu-6.ki-outline:before {
  content: '\e97b';
}
.ki-burger-menu.ki-outline:before {
  content: '\e97c';
}
.ki-bus.ki-outline:before {
  content: '\e97d';
}
.ki-calculator.ki-outline:before {
  content: '\e97e';
}
.ki-calendar-2.ki-outline:before {
  content: '\e97f';
}
.ki-calendar-8.ki-outline:before {
  content: '\e980';
}
.ki-calendar-add.ki-outline:before {
  content: '\e981';
}
.ki-calendar-edit.ki-outline:before {
  content: '\e982';
}
.ki-calendar-remove.ki-outline:before {
  content: '\e983';
}
.ki-calendar-search.ki-outline:before {
  content: '\e984';
}
.ki-calendar-tick.ki-outline:before {
  content: '\e985';
}
.ki-calendar.ki-outline:before {
  content: '\e986';
}
.ki-call.ki-outline:before {
  content: '\e987';
}
.ki-capsule.ki-outline:before {
  content: '\e988';
}
.ki-car-2.ki-outline:before {
  content: '\e989';
}
.ki-car-3.ki-outline:before {
  content: '\e98a';
}
.ki-car.ki-outline:before {
  content: '\e98b';
}
.ki-category.ki-outline:before {
  content: '\e98c';
}
.ki-cd.ki-outline:before {
  content: '\e98d';
}
.ki-celsius.ki-outline:before {
  content: '\e98e';
}
.ki-chart-line-down-2.ki-outline:before {
  content: '\e98f';
}
.ki-chart-line-down.ki-outline:before {
  content: '\e990';
}
.ki-chart-line-star.ki-outline:before {
  content: '\e991';
}
.ki-chart-line-up-2.ki-outline:before {
  content: '\e992';
}
.ki-chart-line-up.ki-outline:before {
  content: '\e993';
}
.ki-chart-line.ki-outline:before {
  content: '\e994';
}
.ki-chart-pie-3.ki-outline:before {
  content: '\e995';
}
.ki-chart-pie-4.ki-outline:before {
  content: '\e996';
}
.ki-chart-pie-simple.ki-outline:before {
  content: '\e997';
}
.ki-chart-pie-too.ki-outline:before {
  content: '\e998';
}
.ki-chart-simple-2.ki-outline:before {
  content: '\e999';
}
.ki-chart-simple-3.ki-outline:before {
  content: '\e99a';
}
.ki-chart-simple.ki-outline:before {
  content: '\e99b';
}
.ki-chart.ki-outline:before {
  content: '\e99c';
}
.ki-check-circle.ki-outline:before {
  content: '\e99d';
}
.ki-check-square.ki-outline:before {
  content: '\e99e';
}
.ki-check.ki-outline:before {
  content: '\e99f';
}
.ki-cheque.ki-outline:before {
  content: '\e9a0';
}
.ki-chrome.ki-outline:before {
  content: '\e9a1';
}
.ki-classmates.ki-outline:before {
  content: '\e9a2';
}
.ki-click.ki-outline:before {
  content: '\e9a3';
}
.ki-clipboard.ki-outline:before {
  content: '\e9a4';
}
.ki-cloud-add.ki-outline:before {
  content: '\e9a5';
}
.ki-cloud-change.ki-outline:before {
  content: '\e9a6';
}
.ki-cloud-download.ki-outline:before {
  content: '\e9a7';
}
.ki-cloud.ki-outline:before {
  content: '\e9a8';
}
.ki-code.ki-outline:before {
  content: '\e9a9';
}
.ki-coffee.ki-outline:before {
  content: '\e9aa';
}
.ki-color-swatch.ki-outline:before {
  content: '\e9ab';
}
.ki-colors-square.ki-outline:before {
  content: '\e9ac';
}
.ki-compass.ki-outline:before {
  content: '\e9ad';
}
.ki-copy-success.ki-outline:before {
  content: '\e9ae';
}
.ki-copy.ki-outline:before {
  content: '\e9af';
}
.ki-courier-express.ki-outline:before {
  content: '\e9b0';
}
.ki-courier.ki-outline:before {
  content: '\e9b1';
}
.ki-credit-cart.ki-outline:before {
  content: '\e9b2';
}
.ki-cross-circle.ki-outline:before {
  content: '\e9b3';
}
.ki-cross-square.ki-outline:before {
  content: '\e9b4';
}
.ki-cross.ki-outline:before {
  content: '\e9b5';
}
.ki-crown-2.ki-outline:before {
  content: '\e9b6';
}
.ki-crown.ki-outline:before {
  content: '\e9b7';
}
.ki-css.ki-outline:before {
  content: '\e9b8';
}
.ki-cube-2.ki-outline:before {
  content: '\e9b9';
}
.ki-cube-3.ki-outline:before {
  content: '\e9ba';
}
.ki-cup.ki-outline:before {
  content: '\e9bb';
}
.ki-dash.ki-outline:before {
  content: '\e9bc';
}
.ki-data.ki-outline:before {
  content: '\e9bd';
}
.ki-delete-files.ki-outline:before {
  content: '\e9be';
}
.ki-delete-folder.ki-outline:before {
  content: '\e9bf';
}
.ki-delivery-2.ki-outline:before {
  content: '\e9c0';
}
.ki-delivery-3.ki-outline:before {
  content: '\e9c1';
}
.ki-delivery-24.ki-outline:before {
  content: '\e9c2';
}
.ki-delivery-door.ki-outline:before {
  content: '\e9c3';
}
.ki-delivery-geolocation.ki-outline:before {
  content: '\e9c4';
}
.ki-delivery-time.ki-outline:before {
  content: '\e9c5';
}
.ki-delivery.ki-outline:before {
  content: '\e9c6';
}
.ki-design-2.ki-outline:before {
  content: '\e9c7';
}
.ki-design-frame.ki-outline:before {
  content: '\e9c8';
}
.ki-design-mask.ki-outline:before {
  content: '\e9c9';
}
.ki-design.ki-outline:before {
  content: '\e9ca';
}
.ki-devices-2.ki-outline:before {
  content: '\e9cb';
}
.ki-devices.ki-outline:before {
  content: '\e9cc';
}
.ki-diamonds.ki-outline:before {
  content: '\e9cd';
}
.ki-directbox-default.ki-outline:before {
  content: '\e9ce';
}
.ki-disconnect.ki-outline:before {
  content: '\e9cf';
}
.ki-discount.ki-outline:before {
  content: '\e9d0';
}
.ki-disk.ki-outline:before {
  content: '\e9d1';
}
.ki-dislike.ki-outline:before {
  content: '\e9d2';
}
.ki-dj.ki-outline:before {
  content: '\e9d3';
}
.ki-document.ki-outline:before {
  content: '\e9d4';
}
.ki-dollar.ki-outline:before {
  content: '\e9d5';
}
.ki-dots-circle-vertical.ki-outline:before {
  content: '\e9d6';
}
.ki-dots-circle.ki-outline:before {
  content: '\e9d7';
}
.ki-dots-horizontal.ki-outline:before {
  content: '\e9d8';
}
.ki-dots-square-vertical.ki-outline:before {
  content: '\e9d9';
}
.ki-dots-square.ki-outline:before {
  content: '\e9da';
}
.ki-dots-vertical.ki-outline:before {
  content: '\e9db';
}
.ki-double-check-circle.ki-outline:before {
  content: '\e9dc';
}
.ki-double-check.ki-outline:before {
  content: '\e9dd';
}
.ki-double-down.ki-outline:before {
  content: '\e9de';
}
.ki-double-left-arrow.ki-outline:before {
  content: '\e9df';
}
.ki-double-left.ki-outline:before {
  content: '\e9e0';
}
.ki-double-right-arrow.ki-outline:before {
  content: '\e9e1';
}
.ki-double-right.ki-outline:before {
  content: '\e9e2';
}
.ki-double-up.ki-outline:before {
  content: '\e9e3';
}
.ki-down-square.ki-outline:before {
  content: '\e9e4';
}
.ki-down.ki-outline:before {
  content: '\e9e5';
}
.ki-dribbble.ki-outline:before {
  content: '\e9e6';
}
.ki-drop.ki-outline:before {
  content: '\e9e7';
}
.ki-dropbox.ki-outline:before {
  content: '\e9e8';
}
.ki-educare.ki-outline:before {
  content: '\e9e9';
}
.ki-electricity.ki-outline:before {
  content: '\e9ea';
}
.ki-electronic-clock.ki-outline:before {
  content: '\e9eb';
}
.ki-element-1.ki-outline:before {
  content: '\e9ec';
}
.ki-element-2.ki-outline:before {
  content: '\e9ed';
}
.ki-element-3.ki-outline:before {
  content: '\e9ee';
}
.ki-element-4.ki-outline:before {
  content: '\e9ef';
}
.ki-element-5.ki-outline:before {
  content: '\e9f0';
}
.ki-element-6.ki-outline:before {
  content: '\e9f1';
}
.ki-element-7.ki-outline:before {
  content: '\e9f2';
}
.ki-element-8.ki-outline:before {
  content: '\e9f3';
}
.ki-element-9.ki-outline:before {
  content: '\e9f4';
}
.ki-element-10.ki-outline:before {
  content: '\e9f5';
}
.ki-element-11.ki-outline:before {
  content: '\e9f6';
}
.ki-element-12.ki-outline:before {
  content: '\e9f7';
}
.ki-element-equal.ki-outline:before {
  content: '\e9f8';
}
.ki-element-plus.ki-outline:before {
  content: '\e9f9';
}
.ki-emoji-happy.ki-outline:before {
  content: '\e9fa';
}
.ki-enjin-coin.ki-outline:before {
  content: '\e9fb';
}
.ki-entrance-left.ki-outline:before {
  content: '\e9fc';
}
.ki-entrance-right.ki-outline:before {
  content: '\e9fd';
}
.ki-eraser.ki-outline:before {
  content: '\e9fe';
}
.ki-euro.ki-outline:before {
  content: '\e9ff';
}
.ki-exit-down.ki-outline:before {
  content: '\ea00';
}
.ki-exit-left.ki-outline:before {
  content: '\ea01';
}
.ki-exit-right-corner.ki-outline:before {
  content: '\ea02';
}
.ki-exit-right.ki-outline:before {
  content: '\ea03';
}
.ki-exit-up.ki-outline:before {
  content: '\ea04';
}
.ki-external-drive.ki-outline:before {
  content: '\ea05';
}
.ki-eye-slash.ki-outline:before {
  content: '\ea06';
}
.ki-eye.ki-outline:before {
  content: '\ea07';
}
.ki-facebook.ki-outline:before {
  content: '\ea08';
}
.ki-faceid.ki-outline:before {
  content: '\ea09';
}
.ki-fasten.ki-outline:before {
  content: '\ea0a';
}
.ki-fat-rows.ki-outline:before {
  content: '\ea0b';
}
.ki-feather.ki-outline:before {
  content: '\ea0c';
}
.ki-figma.ki-outline:before {
  content: '\ea0d';
}
.ki-file-added.ki-outline:before {
  content: '\ea0e';
}
.ki-file-deleted.ki-outline:before {
  content: '\ea0f';
}
.ki-file-down.ki-outline:before {
  content: '\ea10';
}
.ki-file-left.ki-outline:before {
  content: '\ea11';
}
.ki-file-right.ki-outline:before {
  content: '\ea12';
}
.ki-file-sheet.ki-outline:before {
  content: '\ea13';
}
.ki-file-up.ki-outline:before {
  content: '\ea14';
}
.ki-file.ki-outline:before {
  content: '\ea15';
}
.ki-files-tablet.ki-outline:before {
  content: '\ea16';
}
.ki-filter-edit.ki-outline:before {
  content: '\ea17';
}
.ki-filter-search.ki-outline:before {
  content: '\ea18';
}
.ki-filter-square.ki-outline:before {
  content: '\ea19';
}
.ki-filter-tablet.ki-outline:before {
  content: '\ea1a';
}
.ki-filter-tick.ki-outline:before {
  content: '\ea1b';
}
.ki-filter.ki-outline:before {
  content: '\ea1c';
}
.ki-finance-calculator.ki-outline:before {
  content: '\ea1d';
}
.ki-financial-schedule.ki-outline:before {
  content: '\ea1e';
}
.ki-fingerprint-scanning.ki-outline:before {
  content: '\ea1f';
}
.ki-flag.ki-outline:before {
  content: '\ea20';
}
.ki-flash-circle.ki-outline:before {
  content: '\ea21';
}
.ki-flask.ki-outline:before {
  content: '\ea22';
}
.ki-focus.ki-outline:before {
  content: '\ea23';
}
.ki-folder-added.ki-outline:before {
  content: '\ea24';
}
.ki-folder-down.ki-outline:before {
  content: '\ea25';
}
.ki-folder-up.ki-outline:before {
  content: '\ea26';
}
.ki-folder.ki-outline:before {
  content: '\ea27';
}
.ki-frame.ki-outline:before {
  content: '\ea28';
}
.ki-gear.ki-outline:before {
  content: '\ea29';
}
.ki-general-mouse.ki-outline:before {
  content: '\ea2a';
}
.ki-geolocation-home.ki-outline:before {
  content: '\ea2b';
}
.ki-geolocation.ki-outline:before {
  content: '\ea2c';
}
.ki-ghost.ki-outline:before {
  content: '\ea2d';
}
.ki-gift.ki-outline:before {
  content: '\ea2e';
}
.ki-github.ki-outline:before {
  content: '\ea2f';
}
.ki-glass.ki-outline:before {
  content: '\ea30';
}
.ki-google-play.ki-outline:before {
  content: '\ea31';
}
.ki-google.ki-outline:before {
  content: '\ea32';
}
.ki-graph-2.ki-outline:before {
  content: '\ea33';
}
.ki-graph-3.ki-outline:before {
  content: '\ea34';
}
.ki-graph-4.ki-outline:before {
  content: '\ea35';
}
.ki-graph-up.ki-outline:before {
  content: '\ea36';
}
.ki-graph.ki-outline:before {
  content: '\ea37';
}
.ki-grid-2.ki-outline:before {
  content: '\ea38';
}
.ki-grid-frame.ki-outline:before {
  content: '\ea39';
}
.ki-grid.ki-outline:before {
  content: '\ea3a';
}
.ki-handcart.ki-outline:before {
  content: '\ea3b';
}
.ki-happy-emoji.ki-outline:before {
  content: '\ea3c';
}
.ki-heart-circle.ki-outline:before {
  content: '\ea3d';
}
.ki-heart.ki-outline:before {
  content: '\ea3e';
}
.ki-home-1.ki-outline:before {
  content: '\ea3f';
}
.ki-home-2.ki-outline:before {
  content: '\ea40';
}
.ki-home-3.ki-outline:before {
  content: '\ea41';
}
.ki-home.ki-outline:before {
  content: '\ea42';
}
.ki-html.ki-outline:before {
  content: '\ea43';
}
.ki-icon.ki-outline:before {
  content: '\ea44';
}
.ki-illustrator.ki-outline:before {
  content: '\ea45';
}
.ki-information-2.ki-outline:before {
  content: '\ea46';
}
.ki-information-3.ki-outline:before {
  content: '\ea47';
}
.ki-information-4.ki-outline:before {
  content: '\ea48';
}
.ki-information-5.ki-outline:before {
  content: '\ea49';
}
.ki-information.ki-outline:before {
  content: '\ea4a';
}
.ki-instagram.ki-outline:before {
  content: '\ea4b';
}
.ki-joystick.ki-outline:before {
  content: '\ea4c';
}
.ki-js-2.ki-outline:before {
  content: '\ea4d';
}
.ki-js.ki-outline:before {
  content: '\ea4e';
}
.ki-kanban.ki-outline:before {
  content: '\ea4f';
}
.ki-key-square.ki-outline:before {
  content: '\ea50';
}
.ki-key.ki-outline:before {
  content: '\ea51';
}
.ki-keyboard.ki-outline:before {
  content: '\ea52';
}
.ki-laptop.ki-outline:before {
  content: '\ea53';
}
.ki-laravel.ki-outline:before {
  content: '\ea54';
}
.ki-left-square.ki-outline:before {
  content: '\ea55';
}
.ki-left.ki-outline:before {
  content: '\ea56';
}
.ki-like-2.ki-outline:before {
  content: '\ea57';
}
.ki-like-folder.ki-outline:before {
  content: '\ea58';
}
.ki-like-shapes.ki-outline:before {
  content: '\ea59';
}
.ki-like-tag.ki-outline:before {
  content: '\ea5a';
}
.ki-like.ki-outline:before {
  content: '\ea5b';
}
.ki-loading.ki-outline:before {
  content: '\ea5c';
}
.ki-lock-2.ki-outline:before {
  content: '\ea5d';
}
.ki-lock-3.ki-outline:before {
  content: '\ea5e';
}
.ki-lock.ki-outline:before {
  content: '\ea5f';
}
.ki-logistic.ki-outline:before {
  content: '\ea60';
}
.ki-lots-shopping.ki-outline:before {
  content: '\ea61';
}
.ki-lovely.ki-outline:before {
  content: '\ea62';
}
.ki-lts.ki-outline:before {
  content: '\ea63';
}
.ki-magnifier.ki-outline:before {
  content: '\ea64';
}
.ki-map.ki-outline:before {
  content: '\ea65';
}
.ki-mask.ki-outline:before {
  content: '\ea66';
}
.ki-maximize.ki-outline:before {
  content: '\ea67';
}
.ki-medal-star.ki-outline:before {
  content: '\ea68';
}
.ki-menu.ki-outline:before {
  content: '\ea69';
}
.ki-message-add.ki-outline:before {
  content: '\ea6a';
}
.ki-message-edit.ki-outline:before {
  content: '\ea6b';
}
.ki-message-minus.ki-outline:before {
  content: '\ea6c';
}
.ki-message-notif.ki-outline:before {
  content: '\ea6d';
}
.ki-message-programming.ki-outline:before {
  content: '\ea6e';
}
.ki-message-question.ki-outline:before {
  content: '\ea6f';
}
.ki-message-text-2.ki-outline:before {
  content: '\ea70';
}
.ki-message-text.ki-outline:before {
  content: '\ea71';
}
.ki-messages.ki-outline:before {
  content: '\ea72';
}
.ki-microsoft.ki-outline:before {
  content: '\ea73';
}
.ki-milk.ki-outline:before {
  content: '\ea74';
}
.ki-minus-circle.ki-outline:before {
  content: '\ea75';
}
.ki-minus-folder.ki-outline:before {
  content: '\ea76';
}
.ki-minus-square.ki-outline:before {
  content: '\ea77';
}
.ki-minus.ki-outline:before {
  content: '\ea78';
}
.ki-monitor-mobile.ki-outline:before {
  content: '\ea79';
}
.ki-moon.ki-outline:before {
  content: '\ea7a';
}
.ki-more-2.ki-outline:before {
  content: '\ea7b';
}
.ki-mouse-circle.ki-outline:before {
  content: '\ea7c';
}
.ki-mouse-square.ki-outline:before {
  content: '\ea7d';
}
.ki-mouse.ki-outline:before {
  content: '\ea7e';
}
.ki-nexo.ki-outline:before {
  content: '\ea7f';
}
.ki-night-day.ki-outline:before {
  content: '\ea80';
}
.ki-note-2.ki-outline:before {
  content: '\ea81';
}
.ki-note.ki-outline:before {
  content: '\ea82';
}
.ki-notepad-bookmark.ki-outline:before {
  content: '\ea83';
}
.ki-notepad-edit.ki-outline:before {
  content: '\ea84';
}
.ki-notepad.ki-outline:before {
  content: '\ea85';
}
.ki-notification-2.ki-outline:before {
  content: '\ea86';
}
.ki-notification-bing.ki-outline:before {
  content: '\ea87';
}
.ki-notification-circle.ki-outline:before {
  content: '\ea88';
}
.ki-notification-favorite.ki-outline:before {
  content: '\ea89';
}
.ki-notification-on.ki-outline:before {
  content: '\ea8a';
}
.ki-notification-status.ki-outline:before {
  content: '\ea8b';
}
.ki-notification.ki-outline:before {
  content: '\ea8c';
}
.ki-ocean.ki-outline:before {
  content: '\ea8d';
}
.ki-office-bag.ki-outline:before {
  content: '\ea8e';
}
.ki-package.ki-outline:before {
  content: '\ea8f';
}
.ki-pails.ki-outline:before {
  content: '\ea90';
}
.ki-paintbucket.ki-outline:before {
  content: '\ea91';
}
.ki-paper-clip.ki-outline:before {
  content: '\ea92';
}
.ki-parcel-tracking.ki-outline:before {
  content: '\ea93';
}
.ki-parcel.ki-outline:before {
  content: '\ea94';
}
.ki-password-check.ki-outline:before {
  content: '\ea95';
}
.ki-paypal.ki-outline:before {
  content: '\ea96';
}
.ki-pencil.ki-outline:before {
  content: '\ea97';
}
.ki-people.ki-outline:before {
  content: '\ea98';
}
.ki-percentage.ki-outline:before {
  content: '\ea99';
}
.ki-phone.ki-outline:before {
  content: '\ea9a';
}
.ki-photoshop.ki-outline:before {
  content: '\ea9b';
}
.ki-picture.ki-outline:before {
  content: '\ea9c';
}
.ki-pill.ki-outline:before {
  content: '\ea9d';
}
.ki-pin.ki-outline:before {
  content: '\ea9e';
}
.ki-plus-circle.ki-outline:before {
  content: '\ea9f';
}
.ki-plus-square.ki-outline:before {
  content: '\eaa0';
}
.ki-plus.ki-outline:before {
  content: '\eaa1';
}
.ki-pointers.ki-outline:before {
  content: '\eaa2';
}
.ki-price-tag.ki-outline:before {
  content: '\eaa3';
}
.ki-printer.ki-outline:before {
  content: '\eaa4';
}
.ki-profile-circle.ki-outline:before {
  content: '\eaa5';
}
.ki-profile-user.ki-outline:before {
  content: '\eaa6';
}
.ki-pulse.ki-outline:before {
  content: '\eaa7';
}
.ki-purchase.ki-outline:before {
  content: '\eaa8';
}
.ki-python.ki-outline:before {
  content: '\eaa9';
}
.ki-question-2.ki-outline:before {
  content: '\eaaa';
}
.ki-question.ki-outline:before {
  content: '\eaab';
}
.ki-questionnaire-tablet.ki-outline:before {
  content: '\eaac';
}
.ki-ranking.ki-outline:before {
  content: '\eaad';
}
.ki-react.ki-outline:before {
  content: '\eaae';
}
.ki-receipt-square.ki-outline:before {
  content: '\eaaf';
}
.ki-rescue.ki-outline:before {
  content: '\eab0';
}
.ki-right-left.ki-outline:before {
  content: '\eab1';
}
.ki-right-square.ki-outline:before {
  content: '\eab2';
}
.ki-right.ki-outline:before {
  content: '\eab3';
}
.ki-rocket.ki-outline:before {
  content: '\eab4';
}
.ki-route.ki-outline:before {
  content: '\eab5';
}
.ki-router.ki-outline:before {
  content: '\eab6';
}
.ki-row-horizontal.ki-outline:before {
  content: '\eab7';
}
.ki-row-vertical.ki-outline:before {
  content: '\eab8';
}
.ki-safe-home.ki-outline:before {
  content: '\eab9';
}
.ki-satellite.ki-outline:before {
  content: '\eaba';
}
.ki-save-2.ki-outline:before {
  content: '\eabb';
}
.ki-save-deposit.ki-outline:before {
  content: '\eabc';
}
.ki-scan-barcode.ki-outline:before {
  content: '\eabd';
}
.ki-scooter-2.ki-outline:before {
  content: '\eabe';
}
.ki-scooter.ki-outline:before {
  content: '\eabf';
}
.ki-screen.ki-outline:before {
  content: '\eac0';
}
.ki-scroll.ki-outline:before {
  content: '\eac1';
}
.ki-search-list.ki-outline:before {
  content: '\eac2';
}
.ki-security-check.ki-outline:before {
  content: '\eac3';
}
.ki-security-user.ki-outline:before {
  content: '\eac4';
}
.ki-send.ki-outline:before {
  content: '\eac5';
}
.ki-setting-2.ki-outline:before {
  content: '\eac6';
}
.ki-setting-3.ki-outline:before {
  content: '\eac7';
}
.ki-setting-4.ki-outline:before {
  content: '\eac8';
}
.ki-setting.ki-outline:before {
  content: '\eac9';
}
.ki-share.ki-outline:before {
  content: '\eaca';
}
.ki-shield-cross.ki-outline:before {
  content: '\eacb';
}
.ki-shield-search.ki-outline:before {
  content: '\eacc';
}
.ki-shield-slash.ki-outline:before {
  content: '\eacd';
}
.ki-shield-tick.ki-outline:before {
  content: '\eace';
}
.ki-shield.ki-outline:before {
  content: '\eacf';
}
.ki-ship.ki-outline:before {
  content: '\ead0';
}
.ki-shop.ki-outline:before {
  content: '\ead1';
}
.ki-simcard-2.ki-outline:before {
  content: '\ead2';
}
.ki-simcard.ki-outline:before {
  content: '\ead3';
}
.ki-size.ki-outline:before {
  content: '\ead4';
}
.ki-slack.ki-outline:before {
  content: '\ead5';
}
.ki-slider-horizontal-2.ki-outline:before {
  content: '\ead6';
}
.ki-slider-horizontal.ki-outline:before {
  content: '\ead7';
}
.ki-slider-vertical-2.ki-outline:before {
  content: '\ead8';
}
.ki-slider-vertical.ki-outline:before {
  content: '\ead9';
}
.ki-slider.ki-outline:before {
  content: '\eada';
}
.ki-sms.ki-outline:before {
  content: '\eadb';
}
.ki-snapchat.ki-outline:before {
  content: '\eadc';
}
.ki-social-media.ki-outline:before {
  content: '\eadd';
}
.ki-soft-2.ki-outline:before {
  content: '\eade';
}
.ki-soft-3.ki-outline:before {
  content: '\eadf';
}
.ki-soft.ki-outline:before {
  content: '\eae0';
}
.ki-some-files.ki-outline:before {
  content: '\eae1';
}
.ki-sort.ki-outline:before {
  content: '\eae2';
}
.ki-speaker.ki-outline:before {
  content: '\eae3';
}
.ki-spotify.ki-outline:before {
  content: '\eae4';
}
.ki-spring-framework.ki-outline:before {
  content: '\eae5';
}
.ki-square-brackets.ki-outline:before {
  content: '\eae6';
}
.ki-star.ki-outline:before {
  content: '\eae7';
}
.ki-status.ki-outline:before {
  content: '\eae8';
}
.ki-subtitle.ki-outline:before {
  content: '\eae9';
}
.ki-sun.ki-outline:before {
  content: '\eaea';
}
.ki-support-24.ki-outline:before {
  content: '\eaeb';
}
.ki-switch.ki-outline:before {
  content: '\eaec';
}
.ki-syringe.ki-outline:before {
  content: '\eaed';
}
.ki-tablet-book.ki-outline:before {
  content: '\eaee';
}
.ki-tablet-delete.ki-outline:before {
  content: '\eaef';
}
.ki-tablet-down.ki-outline:before {
  content: '\eaf0';
}
.ki-tablet-ok.ki-outline:before {
  content: '\eaf1';
}
.ki-tablet-text-down.ki-outline:before {
  content: '\eaf2';
}
.ki-tablet-text-up.ki-outline:before {
  content: '\eaf3';
}
.ki-tablet-up.ki-outline:before {
  content: '\eaf4';
}
.ki-tablet.ki-outline:before {
  content: '\eaf5';
}
.ki-tag-cross.ki-outline:before {
  content: '\eaf6';
}
.ki-tag.ki-outline:before {
  content: '\eaf7';
}
.ki-teacher.ki-outline:before {
  content: '\eaf8';
}
.ki-tech-wifi.ki-outline:before {
  content: '\eaf9';
}
.ki-technology-2.ki-outline:before {
  content: '\eafa';
}
.ki-technology-3.ki-outline:before {
  content: '\eafb';
}
.ki-technology-4.ki-outline:before {
  content: '\eafc';
}
.ki-technology.ki-outline:before {
  content: '\eafd';
}
.ki-telephone-geolocation.ki-outline:before {
  content: '\eafe';
}
.ki-test-tubes.ki-outline:before {
  content: '\eaff';
}
.ki-text-align-center.ki-outline:before {
  content: '\eb00';
}
.ki-text-align-justify-center.ki-outline:before {
  content: '\eb01';
}
.ki-text-align-left.ki-outline:before {
  content: '\eb02';
}
.ki-text-align-right.ki-outline:before {
  content: '\eb03';
}
.ki-text-bold.ki-outline:before {
  content: '\eb04';
}
.ki-text-circle.ki-outline:before {
  content: '\eb05';
}
.ki-text-italic.ki-outline:before {
  content: '\eb06';
}
.ki-text-number.ki-outline:before {
  content: '\eb07';
}
.ki-text-strikethrough.ki-outline:before {
  content: '\eb08';
}
.ki-text-underline.ki-outline:before {
  content: '\eb09';
}
.ki-text.ki-outline:before {
  content: '\eb0a';
}
.ki-thermometer.ki-outline:before {
  content: '\eb0b';
}
.ki-theta.ki-outline:before {
  content: '\eb0c';
}
.ki-tiktok.ki-outline:before {
  content: '\eb0d';
}
.ki-time.ki-outline:before {
  content: '\eb0e';
}
.ki-timer.ki-outline:before {
  content: '\eb0f';
}
.ki-to-left.ki-outline:before {
  content: '\eb10';
}
.ki-to-right.ki-outline:before {
  content: '\eb11';
}
.ki-toggle-off-circle.ki-outline:before {
  content: '\eb12';
}
.ki-toggle-off.ki-outline:before {
  content: '\eb13';
}
.ki-toggle-on-circle.ki-outline:before {
  content: '\eb14';
}
.ki-toggle-on.ki-outline:before {
  content: '\eb15';
}
.ki-trailer.ki-outline:before {
  content: '\eb16';
}
.ki-trash-square.ki-outline:before {
  content: '\eb17';
}
.ki-trash.ki-outline:before {
  content: '\eb18';
}
.ki-tree.ki-outline:before {
  content: '\eb19';
}
.ki-trello.ki-outline:before {
  content: '\eb1a';
}
.ki-triangle.ki-outline:before {
  content: '\eb1b';
}
.ki-truck.ki-outline:before {
  content: '\eb1c';
}
.ki-ts.ki-outline:before {
  content: '\eb1d';
}
.ki-twitch.ki-outline:before {
  content: '\eb1e';
}
.ki-twitter.ki-outline:before {
  content: '\eb1f';
}
.ki-two-credit-cart.ki-outline:before {
  content: '\eb20';
}
.ki-underlining.ki-outline:before {
  content: '\eb21';
}
.ki-up-down.ki-outline:before {
  content: '\eb22';
}
.ki-up-square.ki-outline:before {
  content: '\eb23';
}
.ki-up.ki-outline:before {
  content: '\eb24';
}
.ki-update-file.ki-outline:before {
  content: '\eb25';
}
.ki-update-folder.ki-outline:before {
  content: '\eb26';
}
.ki-user-edit.ki-outline:before {
  content: '\eb27';
}
.ki-user-square.ki-outline:before {
  content: '\eb28';
}
.ki-user-tick.ki-outline:before {
  content: '\eb29';
}
.ki-user.ki-outline:before {
  content: '\eb2a';
}
.ki-verify.ki-outline:before {
  content: '\eb2b';
}
.ki-vibe.ki-outline:before {
  content: '\eb2c';
}
.ki-virus.ki-outline:before {
  content: '\eb2d';
}
.ki-vue.ki-outline:before {
  content: '\eb2e';
}
.ki-vuesax.ki-outline:before {
  content: '\eb2f';
}
.ki-wallet.ki-outline:before {
  content: '\eb30';
}
.ki-wanchain.ki-outline:before {
  content: '\eb31';
}
.ki-watch.ki-outline:before {
  content: '\eb32';
}
.ki-whatsapp.ki-outline:before {
  content: '\eb33';
}
.ki-wifi-home.ki-outline:before {
  content: '\eb34';
}
.ki-wifi-square.ki-outline:before {
  content: '\eb35';
}
.ki-wifi.ki-outline:before {
  content: '\eb36';
}
.ki-wrench.ki-outline:before {
  content: '\eb37';
}
.ki-xaomi.ki-outline:before {
  content: '\eb38';
}
.ki-xd.ki-outline:before {
  content: '\eb39';
}
.ki-xmr.ki-outline:before {
  content: '\eb3a';
}
.ki-yii.ki-outline:before {
  content: '\eb3b';
}
.ki-youtube.ki-outline:before {
  content: '\eb3c';
}
