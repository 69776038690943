.addDocumentpopup {
  background-color: #f9f9f9;
}
.addDocumentpopup .modal-header {
  background-color: #f9f9f9;
  border-bottom: 1.5px solid #919eab66;
  padding: 15px 20px;
}
.closeBtn {
  color: #fe7253;
}
.addDocument {
  background-color: #f9f9f9;
  border-radius: 5px;
}
.documentBorder {
  border: 1px solid #919eab !important;
}
.documentCheckbox {
  margin-right: 5px;
  border-radius: 3px !important;
  height: 16px;
  width: 16px;
}
.documentCheckboxText {
  color: #212b36;
}
.documentFooter {
  border-top: 1.5px solid #adb5bb99;
}
.documentsaveBtn {
  padding: 7px 30px !important;
}
.documentpopover {
  padding: 10px;
  border-radius: 20px;
}
.addDocumentBtn {
  border-radius: 8px;
}
