.skillPopup {
  background-color: #f9f9f9;
}
.skillPopup .modal-header {
  background-color: #f9f9f9;
  border-bottom: 1.5px solid #919eab66;
  padding: 14px 20px;
}
.closeBtn {
  color: #fe7253;
}
.skillInput {
  /* background-color: #ffffff !important; */
  border: 1px solid #919eab !important;
}
.rateField {
  border: 1px solid #919eab !important;
  color: #000000 !important;
  padding: 15px;
}
#documentRequiredCheckbox {
  height: 20px;
  width: 20px;
  margin-top: 2px;
  border: 1px solid #103558;
}
#hasExpiryCheckbox {
  height: 20px;
  width: 20px;
  margin-top: 2px;
  border: 1px solid #103558;
}
.documentRequiredlabel {
  color: #212b36;
}
.skillPopover {
  padding: 10px;
  border-radius: 20px;
}
.addSkillBtn {
  border-radius: 8px;
}
