.nursingModel {
  background-color: #f9f9f9;
}
.nursingModel .modal-header {
  background-color: #f9f9f9;
  border-bottom: 1.5px solid #919eab66;
  padding: 14px 20px;
}
.nursingModel .closeBtn {
  color: #fe7253;
}
.shiftInput {
  border: 1px solid #919eab !important;
  color: #000000 !important;
  padding: 15px;
}
.createShiftBtn {
  border-radius: 8px;
}
.createShiftPopover {
  padding: 10px;
  border-radius: 20px;
}
