html body {
  font-family: Red Hat Display;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  font-size: 14px !important;
  background-color: #ecebdf;
}
html {
  overflow: auto;
}
body {
  overflow: auto !important;
}
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: Red Hat Display;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -webkit-appearance: textfield; /* Chrome, Safari, Opera */
  -moz-appearance: textfield; /* Firefox */
  appearance: textfield; /* Standard property */
}
.MuiDataGrid-columnHeaderTitle {
  color: #3f4254 !important;
  font-weight: 900 !important;
  font-size: 15px !important;
  font-family: 'DM Sans', sans-serif;
  text-transform: uppercase !important;
}

.MuiDataGrid-columnHeaderTitle:hover {
  color: var(--bs-gray-700) !important;
}

.MuiDataGrid-cellContent,
.MuiDataGrid-cell--withRenderer,
.table-loader-label {
  color: #3f4254 !important;
  font-family: Inter, Helvetica, 'sans-serif';
  font-size: 0.9rem !important;
  letter-spacing: 1.1px;
  font-weight: 500 !important;
}

input[type='time'] {
  display: inline-block;
  position: relative;
}

input[type='time']::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.rmsc .dropdown-container {
  position: relative !important;
  outline: 0 !important;
  background-color: transparent !important;
  border: none !important;
  border-radius: 0 !important;
}

input[type='date'] {
  display: inline-block;
  position: relative;
}

input[type='date']::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

#kt_app_header {
  background-color: rgb(249, 250, 251);
  border-bottom: 1px solid #21212126;
}

.flag-dropdown {
  background-color: #f9f9f9 !important;
  border: none !important;
}

.flag-dropdown:hover {
  background-color: #f9f9f9 !important;
}

/* Added By Nilesh */
.bg-image-shape-box {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  background-color: rgb(249, 250, 251);
}
.login-bg-image {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
  z-index: 1;
}
.login-shape {
  position: absolute;
  z-index: 2;
  width: 11.75rem;
  top: 0;
  left: 2.55rem;
}
.login-form-box {
  background: #103558;
  border-radius: 30px;
  color: #212b36;
  padding: 0.8rem 3.5rem;
}
.logo-box {
  max-width: 240px;
  margin: 0 auto;
  width: 100%;
}
.logo-box figure {
  margin: 0;
}
.logo-box img {
  width: 100%;
  height: auto;
}
.login-form-box h1 {
  font-size: 2.25rem;
  color: #212b36;
}
div .form-control {
  background: #ecebdf;
  box-shadow: none;
  outline: none;
}

.app-sidebar-logo {
  background: rgb(16, 53, 88);
  text-align: center;
  border-color: transparent !important;
}
.app-sidebar-logo a {
  display: table;
  /* margin: 0 auto; */
}
.app-page,
.app-page .app-footer {
  background: rgb(249, 250, 251);
}
.page-heading {
  font-weight: 900;
  color: #212121;
}
.app-wrapper .app-sidebar {
  background: rgb(16, 53, 88);
}
[data-kt-app-layout='light-sidebar']
  div
  .app-sidebar
  .menu
  .menu-item
  .menu-link
  .menu-icon
  i,
[data-kt-app-layout='light-sidebar']
  div
  .app-sidebar
  .menu
  .menu-item
  .menu-link
  .menu-title {
  color: #fff;
  transition: 0.35s all;
}
[data-kt-app-layout='light-sidebar']
  div
  .app-sidebar
  .menu
  .menu-item
  .menu-link.active {
  /* background-color: #eb7626; */
  /* color: #fff; */
  color: rgb(136, 189, 208);
  background-color: rgb(136, 189, 208, 0.08);
}
[data-kt-app-layout='light-sidebar']
  div
  .app-sidebar
  .menu
  .menu-item
  .menu-link.active
  .menu-icon
  i,
[data-kt-app-layout='light-sidebar']
  div
  .app-sidebar
  .menu
  .menu-item
  .menu-link.active
  .menu-title {
  color: #fff;
}
[data-kt-app-layout='light-sidebar']
  div
  .app-sidebar
  .menu
  .menu-item:not(.here)
  .menu-link:hover:not(.disabled):not(.active):not(.here)
  .menu-title,
[data-kt-app-layout='light-sidebar']
  div
  .app-sidebar
  .menu
  .menu-item:not(.here)
  .menu-link:hover:not(.disabled):not(.active):not(.here)
  .menu-icon
  i {
  color: rgb(136, 189, 209);
}
[data-kt-app-layout='light-sidebar']
  div
  .app-sidebar
  .menu
  .menu-item.show
  > .menu-link
  .menu-icon
  i,
[data-kt-app-layout='light-sidebar']
  div
  .app-sidebar
  .menu
  .menu-item.show
  > .menu-link
  .menu-title {
  color: #ffffff !important;
}
.app-sidebar-toggle {
  height: 24px;
  width: 54px;
  padding-left: 30px;
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.app-sidebar-toggle svg {
  width: 100%;
  height: auto;
}
.app-sidebar-toggle-ico svg,
.app-sidebar-toggle svg {
  fill: #212121;
}

.light-placeholder::placeholder {
  color: rgba(99, 114, 129, 0.5);
  font-weight: 300;
}

.app-sidebar-logo .app-sidebar-toggle {
  background: transparent !important;
  box-shadow: none !important;
}
[data-kt-app-sidebar-fixed='true'] .app-wrapper {
  transition: 0.25s all;
}
.fmenu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.fmenu ul li a {
  color: #212121;
  text-decoration: none;
}
.fmenu ul li a:hover {
  color: #eb7626;
}
.fmenu ul li {
  margin: 5px 30px 5px 0;
  line-height: 1;
}
.fmenu ul li:last-child {
  margin-right: 0;
}

.top-search {
  max-width: 300px;
  width: 100%;
  position: relative;
}
.top-search i {
  position: absolute;
  left: 1rem;
  bottom: 0.9rem;
  color: #9a9a9a;
}
.top-search i .path1:before {
  opacity: 1;
}
.top-search .form-control.form-control-solid {
  padding-left: 3.3rem;
  background: #ecebdf;
  border-color: #ecebdf;
  color: #212121;
}
.card .MuiDataGrid-main .MuiDataGrid-row .MuiDataGrid-cell {
  font-size: 13px !important;
  border-color: transparent;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.MuiDataGrid-columnHeaders
  .MuiDataGrid-columnHeadersInner
  .MuiDataGrid-columnHeader:focus {
  outline: none;
}
.MuiDataGrid-columnHeaders
  .MuiDataGrid-columnHeadersInner
  .MuiDataGrid-columnHeader
  .MuiDataGrid-columnHeaderTitle {
  color: #212121 !important;
  font-weight: 700 !important;
  text-transform: none !important;
  font-size: 1.1rem !important;
  font-family: 'DM Sans', sans-serif;
  white-space: pre-wrap;
  line-height: normal;
}
.MuiDataGrid-columnHeaders
  .MuiDataGrid-columnHeadersInner
  .MuiDataGrid-columnHeader {
  border-color: transparent;
  padding: 0 1.5rem;
}
.card .MuiDataGrid-columnHeaders,
.card .MuiDataGrid-root .MuiDataGrid-footerContainer {
  border-color: transparent !important;
}
.card .MuiDataGrid-virtualScrollerContent .MuiDataGrid-row {
  background: #ecebdf80;
}
.card .MuiDataGrid-virtualScrollerContent .MuiDataGrid-row:nth-child(2n + 2) {
  background: #fff;
}
.MuiTablePagination-selectLabel {
  margin-top: 13px;
}
.MuiTablePagination-root .MuiTablePagination-selectLabel,
.MuiTablePagination-root .MuiInputBase-root,
.MuiTablePagination-root .MuiTablePagination-displayedRows {
  color: #212121;
  font-size: 14px;
  font-family: 'DM Sans', sans-serif;
  font-weight: 500;
}
.MuiTablePagination-root .MuiTablePagination-actions .MuiButtonBase-root {
  background: #ecebdf;
  color: #212121;
  margin-right: 10px;
}
.MuiTablePagination-root .MuiTablePagination-actions .MuiButtonBase-root:hover {
  background: #103558;
  color: #fff;
}
.MuiTablePagination-root
  .MuiTablePagination-actions
  .MuiButtonBase-root:last-child {
  margin-right: 0;
  align-items: center;
}

.MuiDataGrid-footerContainer .MuiDataGrid-selectedRowCount {
  color: #212121;
  font-size: 14px;
  font-family: 'DM Sans', sans-serif;
  font-weight: 500;
}
.app-content .form-select.form-select-solid,
.app-content .form-select.form-select-solid,
.app-content .form-control.form-control-solid {
  background-color: #ffff;
  border-color: #919eab;
  color: #212121;
}
.notification-ico {
  position: relative;
  margin: 0 1rem 0 0.5rem;
  cursor: pointer;
}
.notification-ico figure {
  height: 20px;
  width: 20px;
  margin: 0;
}
.notification-ico svg {
  width: 100%;
  height: auto;
  fill: #212121;
}
.notification-ico .count {
  position: absolute;
  top: -7px;
  right: -2px;
  height: 15px;
  width: 15px;
  background: #eb7627;
  color: #fff;
  font-weight: 600;
  font-size: 0.8rem;
  border-radius: 10px;
  text-align: center;
  line-height: 15px;
}
.app-container .card {
  position: relative;
}
.top-title {
  position: absolute;
  left: 1.5rem;
  right: 14rem;
  z-index: 5;
  top: 2.3rem;
}
.top-title h5 {
  margin: 0;
}
.filter-list ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}

.filter-btn {
  height: 40px;
  width: 40px;
  min-width: 40px;
}
.filter-list ul li {
  margin: 0.5rem;
  background: #8081844d;
  padding: 0.7rem 2.6rem 0.6rem 1.3rem;
  border-radius: 100px;
  position: relative;
}
.filter-list ul li:first-child {
  margin-left: 0;
}
.filter-close-ico {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 0.7rem;
  height: 1.4rem;
  width: 1.4rem;
  background: #ecebdf;
  border: 1px solid #fff;
  border-radius: 50px;
  font-weight: bold;
  padding: 0;
  line-height: 1.2rem;
  text-align: center;
  cursor: pointer;
  display: block;
  transition: 0.35s all;
  color: #212121;
}
.filter-close-ico:hover {
  background: #103558;
  border-color: #103558;
  color: #fff;
}
.dashboard-card-box.card {
  position: relative;
  overflow: hidden;
  box-shadow: 0px 0px 30px 0px #2121210d;
  border-radius: 8px;
  z-index: 1;
  background: #fff;
  height: 100%;
  min-height: 260px;
}
.dashboard-card-box.card:hover {
  background: #eb7626;
}

.dashboard-card-box .card-body {
  transition: 0.35s all;
  display: flex;
  flex-direction: column;
}

.dashboard-card-box h5 {
  color: #212121;
  font-size: 1.5rem;
  font-weight: 500;
  transition: 0.35s all;
}
.dashboard-card-box h2 {
  transition: 0.35s all;
}
.dashboard-card-box:hover h5,
.dashboard-card-box:hover h2 {
  color: #fff;
}
.dashboard-card-box figure {
  width: 100px;
  min-width: 100px;
  margin: 0 0 0 auto;
}
.dashboard-card-box figure svg {
  fill: #103558;
  width: 100%;
  transition: 0.35s all;
}
.dashboard-card-box h3 {
  color: #212121;
  font-size: 5rem;
  font-weight: 700;
  margin: 0;
  font-family: 'DM Sans', sans-serif;
  transition: 0.35s all;
}
.dashboard-card-box:hover h3 {
  color: #fff;
}
.dashboard-card-box:hover figure svg {
  fill: #fff;
}
.rate-time {
  color: #212121;
  transition: 0.35s all;
  white-space: nowrap;
}
.dashboard-card-box:hover .rate-time {
  color: #ffffff;
}
.btn.rate-time {
  background: rgba(0, 0, 0, 0.1);
  position: relative;
  display: flex;
  align-items: center;
  transition: 0.35s all;
}
.btn.rate-time:after {
  content: '';
  height: 0;
  width: 0;
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  margin: 0 -0.4rem 0 0.6rem;
  transition: 0.35s all;
}
.dashboard-card-box:hover .btn.rate-time {
  background: rgba(255, 255, 255, 0.2);
}
.dashboard-card-box:hover .btn.rate-time:after {
  border-top-color: rgba(255, 255, 255, 0.7);
}

.dashboard-card-bottom {
  margin-top: auto;
}
.card-bubble {
  position: absolute;
  height: 16rem;
  width: 16rem;
  background: rgba(0, 0, 0, 0.04);
  border-radius: 50%;
  z-index: -1;
}
.dashboard-card-01 .card-bubble {
  bottom: -17%;
  right: -6%;
}
.dashboard-card-02 .card-bubble {
  top: -42%;
  right: -13%;
}
.dashboard-card-03 .card-bubble {
  top: 13%;
  left: -21%;
}
.dashboard-card-04 .card-bubble {
  bottom: -24%;
  right: 13%;
}
.dashboard-card-05 .card-bubble {
  top: -40%;
  left: 50%;
  transform: translateX(-50%);
}
.dashboard-card-06 .card-bubble {
  top: 10%;
  right: -17%;
}
.react-tel-input .selected-flag {
  outline: none !important;
  position: relative !important;
  height: 100% !important;
  border-radius: 3px 0 0 3px !important;
  width: 0px !important;
  padding: 0px !important;
}
.react-tel-input .selected-flag .flag {
  margin-left: 20px !important;
}
.form-control.form-control-solid {
  margin-bottom: 0px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.react-tel-input .form-control {
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}
.input-field {
  padding: 10px;
}

#confirmNewPassword::placeholder {
  color: '#212B36' !important;
}

/* .MuiDataGrid-row .MuiDataGrid-row--firstVisible{
    min-height: 78px !important;
    max-height: 52px !important;
    min-height: 52px !important;
    --height: 52px !important;
}

.cell-padding {
  padding: 10px !important; 
  height: 60px !important;
}  */
.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none !important;
}
.css-igs3ac {
  border: none !important;
}
@media only screen and (max-width: 1399px) {
  .dashboard-card-box figure {
    width: 70px;
    min-width: 70px;
  }
  .dashboard-card-box h3 {
    font-size: 4rem;
  }
  .dashboard-card-box h5 {
    font-size: 1.4rem;
  }
  .dashboard-card-bottom {
    padding-top: 5rem;
  }
  .card-bubble {
    height: 12rem;
    width: 12rem;
  }
}
@media only screen and (max-width: 1199px) {
  .login-form-box {
    padding: 4.5rem;
  }
  .dashboard-card-box h3 {
    font-size: 3.2rem;
  }
  .dashboard-card-box figure {
    width: 60px;
    min-width: 60px;
  }
  .dashboard-card-bottom {
    padding-top: 3rem;
  }
}
@media only screen and (max-width: 991px) {
  .shiftdetailStep {
    padding-left: 0px !important;
    border-left: none !important;
  }
  .patientInfo {
    /* padding-left: 10px !important; */
    border-left: none !important;
  }
}
@media only screen and (max-width: 767px) {
  .login-form-box {
    padding: 4rem;
  }
  .top-title {
    position: static;
    margin-bottom: 1.5rem;
  }
  .dashboard-card-box figure {
    width: 70px;
    min-width: 70px;
  }
  .clockDetails {
    border-left: 0 !important;
    border-top: 1px solid #919eab;
  }
  .timesheetDetails {
    border-left: none !important;
    padding-left: 0 !important;
  }
}
@media only screen and (max-width: 575px) {
  .login-form-box {
    padding: 3rem;
  }
  .top-search {
    max-width: 100%;
  }
}
/* .css-k21lcl-MuiDataGrid-root .MuiDataGrid-virtualScrollerContent .MuiDataGrid-row{
  max-height: 70px !important;
  min-height: 70px !important;
  align-items: center !important;
}  */
.providerSkill .react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.react-datepicker__input-container .react-datepicker__calendar-icon {
  margin-top: 10px !important;
}
.snackbar-success {
  min-width: 250px !important; /* Minimum width */
  max-width: 80% !important; /* Maximum width */
  border-radius: 4px !important; /* Rounded corners */
  display: inline-flex !important; /* Use inline-flex for automatic width adjustment */
  align-items: center !important; /* Center items vertically */
  word-wrap: break-word !important; /* Ensure long words break properly */
}

.snackbar-error {
  min-width: 250px !important; /* Minimum width */
  max-width: 80% !important; /* Maximum width */
  border-radius: 4px !important; /* Rounded corners */
  display: inline-flex !important; /* Use inline-flex for automatic width adjustment */
  align-items: center !important; /* Center items vertically */
  word-wrap: break-word !important; /* Ensure long words break properly */
}
.providerSkill .react-datepicker-wrapper {
  width: 100%;
}

.css-1j0ybez {
  border: none !important;
}

.uploadfileList i {
  position: absolute;
  top: 0;
  right: 10px;
  height: -webkit-fill-available;
  display: flex;
  align-items: center;
  color: #000000 !important;
}
[data-kt-app-sidebar-minimize='on']:not([data-kt-app-sidebar-hoverable='true'])
  .app-sidebar
  .app-sidebar-menu
  .menu-content,
[data-kt-app-sidebar-minimize='on']:not([data-kt-app-sidebar-hoverable='true'])
  .app-sidebar
  .app-sidebar-logo
  .logoText {
  /* display: none !important; */
  opacity: 0 !important;
  transition: 0.4s;
}
.clockDetails {
  border-left: 1px solid #919eab;
}

.scrollbarHide::-webkit-scrollbar {
  display: none !important;
}
.scrollbarHide {
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}
.fade.modal.show {
  padding-left: 0px !important;
}
.spinner-loader {
  display: flex !important;
  position: fixed !important;
  width: 100% !important;
  height: 100vh !important;
  align-items: center !important;
  justify-content: center !important;
  z-index: 1060 !important;
  border-radius: 5px !important;
  margin-top: 23px !important;
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
}
.shiftDetail-sticky {
  position: sticky !important;
  top: 80px;
  margin-bottom: 20px;
}
.form-control.passwordChange {
  border: 1px solid #919eab !important;
  background-color: transparent !important;
}
details {
  background-color: #ffffff;
  border-radius: 5px;
  padding: 10px 20px;
  margin-bottom: 10px;
  box-shadow: rgba(0, 0, 0, 0.04) 0 2px 10px 0 !important;
}

.details-title {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}
.css-1jr10t1.MuiFormControl-root {
  border: none !important;
}
.css-1bn53lx {
  padding: 5px 15px 4px 5px;
}
.css-lm7p4k {
  border: none !important;
}
.css-igs3ac {
  border-color: #919eab !important;
}

.details-title {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  cursor: pointer; /* Ensure the pointer cursor on hover */
}

.details-title span {
  transition: transform 0.6s ease;
}
.uploadedFile {
  width: 86%;
}
/* overflow:"hidden", maxWidth:"100%", textOverflow:"ellipsis", width:"content", whiteSpace:"nowrap" */
.uploadedFile p {
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  width: fit-content;
  white-space: nowrap;
}
.uploadedFileclinician {
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  width: fit-content;
  white-space: nowrap;
}
.customeboxShadow {
  box-shadow: rgba(0, 0, 0, 0.04) 0 2px 10px 0 !important;
}

.css-1q04gal-MuiDateCalendar-root {
  max-height: 290px !important;
}
.css-17fk442 .MuiDataGrid-columnHeader:focus-within,
.css-17fk442 .MuiDataGrid-cell:focus-within {
  outline: none !important;
}
.selected-dates-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  border: 1px solid #919eab;
  padding: 10px 16px;
  border-radius: 8px;
}
.chip {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  border-radius: 16px;
  background-color: #dde1e6;
  font-size: 14px;
}
.remove-icon {
  color: red;
}
.css-1j0ybez.MuiFormControl-root {
  border: 1px solid #919eab !important;
  justify-content: center !important;
}
.form-select:disabled {
  color: #000000 !important;
}
.stsusDatadesign {
  height: 30px;
  border-radius: 5px;
  padding: 10px;
  min-width: 80px;
  justify-content: center;
}
.css-1aj41gs::before {
  background-color: transparent !important;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root.MuiAccordion-rounded.MuiAccordion-gutters.css-1aj41gs {
  margin-bottom: 10px !important;
  border-radius: 4px !important;
}
.css-1oqimao.Mui-expanded {
  max-height: 58px !important;
  min-height: 58px !important;
  border-bottom: 1px solid #00000059 !important;
}

.css-1086bdv-MuiPaper-root-MuiAccordion-root::before {
  background-color: transparent !important;
}
.css-1086bdv-MuiPaper-root-MuiAccordion-root {
  margin-bottom: 10px !important;
  border-radius: 4px !important;
}
.css-1f773le-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
  max-height: 58px !important;
  min-height: 58px !important;
  border-bottom: 1px solid #00000059 !important;
}

.css-1x9nb-MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainerContent {
  cursor: default !important;
}
.card .MuiDataGrid-main .MuiDataGrid-row .MuiDataGrid-cell {
  cursor: default !important;
}

.css-17fk442 .MuiDataGrid-columnHeaderTitleContainerContent {
  cursor: default !important;
}
.card .MuiDataGrid-main .MuiDataGrid-row .MuiDataGrid-cell {
  cursor: default !important;
}
.autofillField:-webkit-autofill {
  background-color: #f9f9f9 !important;
  box-shadow: 0 0 0 50px #f9f9f9 inset !important;
  animation-duration: 5000s;
}
.autofillField:focus {
  outline: none;
}
.autofillFieldwhite:-webkit-autofill {
  background-color: #ffffff !important;
  box-shadow: 0 0 0 50px #ffffff inset !important;
  animation-duration: 5000s;
}

.css-1k4oq0i-MuiPaper-root-MuiPickersPopper-paper{
  position: relative;
    bottom: 80px;
}

.css-1e6y48t-MuiButtonBase-root-MuiButton-root:hover {
  -webkit-text-decoration: none;
  text-decoration: none !important;
  background-color: #ffffff !important;
}

.timsheet-filter-datepicker{
  width: 35%;
}

.css-nk89i7-MuiPickersCalendarHeader-root{
  justify-content: center !important;
  margin-left: 20px;
}

.css-cyfsxc-MuiPickersCalendarHeader-labelContainer{
  margin-right: 0 !important;
}

.css-kg9q0s-MuiButtonBase-root-MuiIconButton-root-MuiPickersArrowSwitcher-button{
  position: absolute !important;
  left: 0;
  top: 12px;
}

.css-1nkg345-MuiButtonBase-root-MuiIconButton-root-MuiPickersArrowSwitcher-button{
  position: absolute !important;
  right: 0;
  top: 12px;
}

.css-16j77m4{
  margin-right: 0 !important;
}
.css-11wxb{
  position: absolute !important;
  left: 0;
  top: 12px;
}

.css-1fklenr{
  position: absolute !important;
  right: 0;
  top: 12px;
}
.css-1dozdou{
  justify-content: center !important;
  margin-left: 20px;
}

.clinicianNotesDisable{
  background-color: #e0e0e0 !important;
}

.datePickerDisable{
  background-color: #bcb4b4;
}