.nursingHomepopup {
  background-color: #f9f9f9;
}
.nursingHomepopup .modal-header {
  background-color: #f9f9f9;
  border-bottom: 1.5px solid #919eab66;
  padding: 14px 20px;
}
.nursingHomepopup .close {
  color: #fe7253;
}
.inputField {
  border: 1px solid #919eab !important;
  color: #000000 !important;
  padding: 15px;
}
.stateSelect {
  border: 1px solid #919eab !important;
}
.nursingPopover {
  padding: 10px;
  border-radius: 20px;
}
.addNursingbtn {
  border-radius: 8px;
}
