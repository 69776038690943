.shift-popover {
  padding: 10px;
  border-radius: 20px;
  box-shadow: none;
}
.backButton {
  border-radius: 100%;
  padding: 8px;
  cursor: pointer;
  display: inline-flex;
  background-color: #f0f0f0;
  align-items: center;
}
.borderBottom {
  border-bottom: 1px solid #919eab;
}
.cancelAssignmentbtn {
  background-color: #ff5630;
  color: #ffffff;
  border-radius: 8px;
  padding: 8px 16px;
  border: 1px solid #ff5630;
}
.shieftHeading {
  color: #212b36;
}
.shieftData {
  color: #212b36;
  font-weight: 400;
}
.shieftData-bold{
  font-weight: bold;
}
.shieftCancled {
  color: #ff0000;
}
.shieftData .heading {
  font-size: 14px;
  color: #212b36;
  margin: 0;
  font-weight: 400;
}
.documentCard {
  background-color: #f1f3f4;
  border-radius: 5px;
  margin-bottom: 10px;
  box-shadow: rgb(0 0 0 / 10%) 1px 1px 4px 2px;
}
.shieftLable {
  font-size: 16px;
  padding: 5px 10px;
  border-radius: 5px;
  margin-right: 10px;
}
.cancleShieftBtn {
  background-color: #ff5630;
  color: #ffffff;
  border: 1px solid #ff5630;
  border-radius: 8px;
  padding: 6px 10px;
  height: 31px;
  margin-right: 10px;
}
.noData {
  color: #212b36;
}
.shiftdetailStep {
  border-left: 2px solid #919eab14;
  padding-left: 10px;
}
.clockinTime {
  color: #ffffff;
  font-size: 12px;
  display: block;
  padding: 3px;
  border-radius: 5px;
  text-align: center;
  width: 55px;
}
.notimeData {
  height: 50px;
}
.shieftStstus {
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 0;
  margin-left: 5px;
}
.shieftRejectButton {
  background-color: #ff5630;
  color: #ffffff;
  border: 1px solid #ff5630;
  border-radius: 8px;
  padding: 6px 10px;
  height: 31px;
  width: 90px;
  margin-right: 10px;
  margin-bottom: 5px;
}
.shieftApproveButton {
  background-color: #107fa9;
  color: #ffffff;
  border: 1px solid #107fa9;
  border-radius: 8px;
  padding: 6px 10px;
  height: 31px;
  width: 90px;
}
.cancletimeShieft {
  background-color: #ff5630;
  color: #ffffff;
  border: 1px solid #ff5630;
  border-radius: 8px;
  padding: 6px 10px;
  height: 31px;
  margin-right: 10px;
}
.viewTimesheetBtn {
  background-color: #103558;
  color: #ffffff;
  font-size: 12px;
  border-radius: 8px;
  padding: 6px 10px;
  border: 1px solid #103558;
  align-items: end;
  height: 31px;
}
.uploadDocument {
  color: #212b36;
  border-bottom: 1px solid #00000059;
}
.uploadfileList {
  border-radius: 5px;
  padding: 10px 15px;
  border: 1px solid #e9e9e9;
  margin-bottom: 15px;
  position: relative;
}
.uploadfileList-label {
  color: #212b36;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  white-space: nowrap;
}
.circle-disable {
  background-color: transparent;
}
.circle-bg {
  border: 2px solid #103558;
  height: 12px;
  width: 12px;
  border-radius: 50px;
  position: absolute;
  top: 4px;
  left: 0;
  background-color: #103558;
}
.circle-box {
  position: relative;
  padding-bottom: 8px;
}
.circle {
  border: 2px solid #103558;
  height: 12px;
  width: 12px;
  border-radius: 50px;
  position: absolute;
  top: 4px;
  background-color: #ffffff;
  left: 0;
}
.circle-box::before {
  content: '';
  height: -webkit-fill-available;
  width: 2px;
  background-color: #000000;
  position: absolute;
  top: 0;
  left: 5px;
}
.circle-box:first-child::before {
  top: 10px;
}
.circle-box:last-child::before {
  bottom: 14px;
  height: 12px;
}
