.programPopover {
  padding: 10px;
  border-radius: 20px;
}
.addProgramBtn {
  border-radius: 8px;
}
.programModel {
  background-color: #f9f9f9;
}
.programModel .modal-header {
  background-color: #f9f9f9;
  border-bottom: 1.5px solid #919eab66;
  padding: 14px 20px;
}
.closeBtn {
  color: #fe7253;
}
.programInput {
  /* background-color: #ffffff !important; */
  border: 1px solid #919eab !important;
}
