.modal-dialog-right {
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    width: 35%;
    margin: 0;
    height: 100vh;
    transition: transform 0.3s ease-in-out;
  }
  
  .modal-content {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .modal-body {
    flex: 1;
    overflow-y: auto;
    padding: 15px;
  }
  
  .notification-item {
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }
  
  .notification-icon {
    font-size: 24px;
    margin-right: 10px;
  }
  
  .notification-content {
    flex: 1;
  }
  
  .notification-item.read {
    opacity: 0.6;
  }

  .notificationCount{
    position: absolute !important;
    top: -8px;
    right: -8px;
    font-size: 10px;
    border-radius: 100%;
    background-color: #107fa9;
    padding: 10px !important;
    height: 18px;
    width: 18px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    color: #ffffff;
  }

  .bgForRead{
    background-color: #919eab33;
  }

  @media (max-width: 767px) {
    .modal-dialog-right {
      width: 70%;
    }
}