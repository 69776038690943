.companyModel {
  background-color: #f9f9f9;
}
.companyModel .modal-header {
  background-color: #f9f9f9;
  border-bottom: 1.5px solid #919eab66;
}
.closeBtn {
  color: #fe7253;
}
.viewImageLabel {
  height: 140px;
  width: 140px;
  background-color: #ffffff !important;
  position: relative;
  border: 2px solid #919eab !important;
  border-radius: 5px;
  overflow: hidden;
  padding: 0;
}
.viewCompanyImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  border-radius: 2px;
}
.viewCompanySvg {
  width: 140px;
  height: 141px;
  position: absolute;
  top: 0;
  left: -2px;
}
.viewCompanyName {
  font-size: 16px;
  color: #103558;
}
.viewCompanyUrl {
  font-size: 16px;
  color: #212b36;
  padding-top: 4px;
}
.viewCompanydescription {
  color: #212b36;
  font-size: 14px;
  padding-top: 4px;
}
.titleFont {
  color: #212b36;
  font-size: 18px;
  font-weight: 500;
}
.addressBox {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding: 10px 15px;
  border-radius: 8px;
  margin-bottom: 10px;
}
.primaryAddress {
  color: #103558;
}
.contactInfoBox {
  background-color: #ffffff;
  padding: 10px 15px;
  border-radius: 8px;
}
.companyPopover {
  padding: 10px;
  border-radius: 20px;
}
.addImage {
  position: relative;
  width: 140px;
  height: 140px;
  margin-top: 10px;
  border: 2px solid #919eab;
  border-radius: 8px;
  overflow: hidden;
}
.addImageLabel {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 5px;
  border: none;
}
.addImageinput {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  padding: 0;
}
.uplodedImageFile {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  border-radius: 5px;
}
.demoSvgImage {
  width: 140px;
  height: 140px;
  position: absolute;
  top: 0;
  left: -1px;
}
.addImageIcon {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 24px;
  height: 24px;
  margin: -1px;
}
.companyInput {
  border: 1px solid #919eab !important;
  color: #000000 !important;
  padding: 15px;
}
.companyInput.textarea {
  height: 80px;
}
.primaryAddressCheckbox {
  height: 18px;
  width: 18px;
  border-radius: 3px !important;
}
.addressInputStyle {
  background-color: #ffffff !important;
  border: 1px solid #919eab !important;
}
.addressDelete {
  font-size: 16px;
}
.skillSelection {
  border: 1px solid #919eab;
  border-radius: 8px;
}
#isRequiredVitas {
  margin-right: 5px;
  text-align: center;
  border-radius: 3px;
  height: 18px;
  width: 18px;
}
.vitasworkLabel {
  color: #212b36;
}
