.chooseFile {
  border: 1px solid rgb(145, 158, 171);
  width: 100%;
  padding: 8px 12px;
}
.chooseFile.Onboarding {
  padding: 12px !important;
}
.selectedFile {
  background-color: #10355812;
  padding: 6px 15px;
  border-radius: 5px;
  border: 1px solid rgb(145, 158, 171);
}
/* .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root{
    height: 45px !important;
} */
.selectProgram.form-control.form-control-solid {
  padding: 3px 0 0 0 !important;
}
.popover {
  min-width: 300px;
}
.popover .popover-header {
  font-size: 18px !important;
  padding: 12px 24px;
}
.popover .popover-body {
  padding: 12px 24px;
}
.popover .popover-body label {
  margin-bottom: 6px;
  font-size: 16px;
}
.popover .popover-body label svg {
  fill: #1b676a;
  height: 18px;
  width: 18px;
}
.backArrow {
  border-radius: 100%;
  padding: 8px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  background-color: #f0f0f0;
}
.titleBox {
  border-bottom: 2px solid #919eab66;
  padding: 15px 20px;
}
.requriedField {
  color: #ff0000;
}
.fontTitle {
  font-size: 18px;
}
.backgroundExpiry {
  font-weight: 500;
  font-size: 12px;
}
.addClinician {
  border-radius: 8px;
}
.viewClinicianheader {
  background-color: #f9f9f9;
  border-bottom: 1.5px solid #919eab66;
}
.closeBtn {
  color: #fe7253;
}
.viewClinicianModel {
  background-color: #f9f9f9;
}
.clinicianUserlabel {
  height: 140px;
  width: 140px;
  background-color: #ffffff !important;
  position: relative;
  border: 2px solid #919eab !important;
  border-radius: 5px;
  overflow: hidden;
  padding: 0;
}
.clinicianUserimg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
  border-radius: 2px;
}
.clinicianName {
  font-size: 16px;
  color: #103558;
  font-weight: 700;
}
.clinicianNumber {
  font-size: 14px;
  color: #212b36;
  font-weight: 500;
}
.clinicianAddress {
  font-size: 14px;
  color: #212b36;
  max-width: 260px;
}
.clinicianStatus {
  color: #212b36;
  margin: 0px;
  line-height: 20px;
}
.rejectButton {
  background-color: #ff5630 !important;
  color: #ffffff !important;
  font-size: 14px;
  border-radius: 8px;
  margin-right: 10px;
  width: 75px;
  height: 31px;
  padding: 6px 16px !important;
}
.approveButton {
  background-color: #027ea8 !important;
  color: #ffffff !important;
  cursor: pointer !important;
  font-size: 14px;
  border-radius: 8px;
  margin-right: 10px;
  width: 90px;
  height: 31px;
  padding: 6px 16px !important;
}
.selectionBorder {
  border: 1px solid #d3e5ec;
  border-radius: 8px;
}
.programs-text {
  color: #103558;
}
.assigned-programs {
  background-color: #f6851f !important;
  font-weight: 600;
  color: #ffffff !important;
  border-color: #f6851f !important;
}
#documentVerifyBtn {
  background-color: #007867 !important;
  color: #ffffff !important;
  font-size: 11px;
  margin-right: 10px;
  border-radius: 8px;
  padding: 6px 10px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 3px;
}
#documentRejectBtn {
  background-color: #ff5630 !important;
  color: #ffffff !important;
  font-size: 11px;
  border-radius: 8px;
  padding: 6px 10px !important;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 3px;
}
.typeDate {
  border-top: 1px solid #e6e6e6;
}
.documentType {
  font-size: 12px;
}
.clinicianFooter {
  border-top: 1.5px solid #919eab66;
}
.clinicianSaveBtn {
  padding: 7px 45px !important;
}
.clinicianProfile {
  position: relative;
  width: 140px;
  height: 140px;
  margin-top: 10px;
  border: 2px solid #919eab;
  border-radius: 8px;
  overflow: hidden;
}
.clinicianLabel {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 5px;
  border: none;
}
.clinicianInput {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  padding: 0;
}
.clinicianUploadedImg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
  border-radius: 0.625rem;
}
.clinicianSvgimage {
  width: 140px;
  height: 140px;
  position: absolute;
  top: 0;
  left: -6px;
}
.uploadIconimg {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 24px;
  height: 24px;
  margin: -1px;
}
.selectCompany {
  background-color: #ffffff;
  border: 1px solid #919eab;
}
#viewStstusrejectBtn {
  background-color: #ff5630 !important;
  color: #ffffff;
  font-size: 14px;
  border-radius: 8px;
  margin-right: 10px;
  width: 75px;
  height: 31px;
  padding: 6px 16px;
}
#viewStstusApproveBtn {
  background-color: #027ea8 !important;
  color: #ffffff;
  cursor: pointer;
  font-size: 14px;
  border-radius: 8px;
  margin-right: 10px;
  width: 90px;
  height: 31px;
  padding: 6px 16px;
}
#viewStatusRevertRejectBtn {
  background-color: #027ea8 !important;
  color: #ffffff;
  cursor: pointer;
  font-size: 14px;
  border-radius: 8px;
  margin-right: 10px;
  width: 138px;
  height: 33px;
  padding: 6px 16px;
}
.statusApprove {
  color: #007867;
  background-color: #00786729;
  padding: 5px;
  font-size: 14px;
  border-radius: 4px;
}
.statusSuspend {
  color: #ff5630;
  background-color: #ff563029;
  padding: 5px;
  font-size: 14px;
  border-radius: 4px;
}
.statusPending {
  color: #f6851f;
  background-color: #f6851f29;
  padding: 5px;
  font-size: 14px;
  border-radius: 4px;
}
.dnrCompanyList {
  color: #212b36;
}
.historyStatus {
  font-size: 14px;
  font-weight: bold;
}
.approveStatus {
  font-size: 14px;
  border-radius: 8px;
  margin-right: 10px;
  width: 90px;
  padding: 6px 16px !important;
}
.documentReject {
  color: #ff5630;
}
.documentTypeBorder {
  border-top: 1px solid #e6e6e6;
}
.documentNametext {
  font-size: 16px;
  font-weight: 600;
  margin: 0px;
}
.documentBox {
  padding: 12px;
  background-color: #ffffff;
  border-radius: 8px !important;
  justify-content: space-between;
  cursor: pointer;
  box-shadow: rgb(0 0 0 / 10%) 0 2px 12px 0px !important;
  min-height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.rejectedclinician {
  padding: 12px;
  background-color: #e0e0e0 !important;
  border-radius: 8px !important;
  justify-content: space-between;
  cursor: pointer;
  box-shadow: rgb(0 0 0 / 10%) 0 2px 12px 0px !important;
  min-height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.clinicianVerified{
  color: #007867 !important;
}
.disableVitas{
  background-color: #e0e0e0 !important;
}