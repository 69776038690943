.adminPopover {
  padding: 10px;
  border-radius: 20px;
}
.addAdminBtn {
  border-radius: 8px;
}
.adminModel {
  background-color: #f9f9f9;
}
.adminModel .modal-header {
  background-color: #f9f9f9;
  border-bottom: 1.5px solid #919eab66;
  padding: 14px 20px;
}
.closeBtn {
  color: #fe7253;
}
.nameField {
  /* background-color: #ffffff !important; */
  border: 1px solid #919eab66 !important;
}
#selectedRole {
  border: 1px solid #919eab66;
}
.companyAdminselect {
  border: 1px solid #919eab66;
  border-radius: 8px;
  background-color: #ffffff;
}
